import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { FaFacebookF, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer mt-auto text-left">
      <Container>
        <Row className="mt-3">
          <Col
            sm={{ span: 3 }}
            md={{ span: 5 }}
            lg={{ span: 3 }}
            className="mb-30"
          >
            <StaticImage src="../images/logo.png" alt="Logo" />
          </Col>
          <Col md={{ span: 4 }} lg={{ span: 4 }} className="mb-30">
            <h5>About Us</h5>
            <p>
              Young Home Inspections is a premier inspection company that has
              been in business and providing the highest quality inspections for
              over 30 years. Our inspection team is licensed & insured to
              perform inspections for residential, commercial, insurance, mold,
              and more.
            </p>
          </Col>
          <Col md={{ span: 3 }} lg={{ span: 4 }} className="mb-30">
            <h5>Contact Info</h5>
            <p>Location: New Port Richey, FL</p>
            <h6>
              <FaPhoneAlt />{" "}
              <a href="tel:+17277335186" className="footer-link">
                Call Us: (727)733-5186
              </a>
            </h6>
            <h6>
              <FaFacebookF />{" "}
              <a
                href="https://www.facebook.com/YoungHomeInspections/"
                className="footer-link"
              >
                Visit our Facebook
              </a>
            </h6>
            <h5>
              <a
                href="http://webreportkeeper.com/calendar4/login-form.php"
                className="footer-login"
              >
                Employee Login
              </a>
            </h5>
          </Col>
        </Row>
      </Container>
      <section className="footer-bottom">
        <Container>
          <Row className="text-center">
            <Col sm={3} md={5}>
              {" "}
              <h6>
                © {new Date().getFullYear()}, Young Home Inspections, All Rights
                Resevered.
              </h6>{" "}
            </Col>
            <Col sm={3} md={6} className="footer-bottomright">
              <h6>
                Site Design:{" "}
                <a href="https://cflms.com/" className="footer-link">
                  Central Florida Media Solutions
                </a>
              </h6>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
