import React from "react";
import Header from "../components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import PropTypes from "prop-types";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        <div>{children}</div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
